//Floatui component https://www.floatui.com/

import { useState } from "react";
import gameLogo from "../resources/GameIcon.png";

const Navbar = () => {
  const [state, setState] = useState(false);
  const pathname = window.location.pathname;
  let navigation = [];
  if (pathname !== "/devteam") {
    navigation = [
      { title: "Trailer", path: "#trailerVideo" },
      { title: "Beta cerrada", path: "#newsletter" },
      { title: "Preguntas frecuentes", path: "#FAQ" },
      { title: "Avengers Studios", path: "/devteam" },
    ];
  }

  // Replace javascript:void(0) path with your path

  return (
    <nav className="bg-stone-900 w-full border-b md:border-0 md:static mt- mb-5">
      <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
        <div className="flex items-center justify-between py-3 md:py-5 md:block">
          <a className="flex" href="/">
            <img src={gameLogo} width={50} height={50} alt="Float UI logo" />
            <div className="font-bold text-white text-lg ml-5 grid place-content-center">
              <span className="self-center">Element Dungeon</span>
              <span className="self-center">The Awakening</span>
            </div>
          </a>
          <div className="md:hidden">
            <button
              className="text-gray-100 outline-none bg-lime-600 p-2 rounded-md focus:border-grey-500 focus:border"
              onClick={() => setState(!state)}
            >
              {state ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${state ? "block" : "hidden"}`}>
          <ul className="justify-center items-center space-y-8 md:flex md:space-x-10 md:space-y-0">
            {navigation.map((item, idx) => {
              return (
                <li key={idx} className="text-white font-bold  hover:text-lime-500">
                  <a href={item.path}>{item.title}</a>
                </li>
              );
            })}
          </ul>
        </div>
        {pathname !== "/devteam" && (
          <div className="hidden md:inline-block">
            <a href="#newsletter" className="py-3 px-4 text-white bg-lime-600 hover:bg-lime-500 rounded-md shadow">
              Descargar
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
