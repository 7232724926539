import archer from "../resources/archer.gif";
import knight from "../resources/knight.gif";
import mage from "../resources/mage.gif";
import skeleton from "../resources/skeleton.gif";

const Quickstory = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-4 px-4 sm:px-8 mt-20">
      <div className="grid sm:grid-cols-2">
        <div className="py-4">
          <h3 className="text-3xl text-gray-800 font-semibold md:text-4xl">
            Descubre el secreto de los <span className="text-red-800">esqueletos</span>
          </h3>
          <p className="text-xl text-gray-600 leading-relaxed mt-3 text-justify">
            "El reino de Camlot fue invadido por esqueletos místicos con poderes elementales. El ejército del rey
            Leónidas valientemente aisló cada elemento en un punto del reino Camlot antes de desaparecer
            misteriosamente. Así comienza la historia de nuestros héroes, tres amigos con gran valentía y talentos
            únicos. Su objetivo es simple: Derrotar a cada jefe esqueleto, recuperar los talismanes elementales y
            descubrir el secreto escondido en los calabozos.
          </p>
          <a
            className="cta-pr-btn px-4 py-2 text-white font-medium bg-stone-900 rounded-full inline-flex items-center my-10"
            href="javascript:void()"
          >
            Leer el pergamino de tierra
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 ml-1 duration-150"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </a>
        </div>
        <div className="flex sm:pl-20">
          <img src={mage} className="h-40 self-center -mr-20 sm"></img>
          <img src={archer} className="h-40 self-center -mr-20"></img>
          <img src={knight} className="h-40 self-center -mr-20 sm:mr-0"></img>
          <img src={skeleton} className="h-40 self-center"></img>
        </div>
      </div>

      <style jsx>{`
        .cta-pr-btn:hover svg {
          transform: translateX(5px);
        }
      `}</style>
    </section>
  );
};

export default Quickstory;
