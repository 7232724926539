import React from "react";

import MainBanner from "../../MainBanner/MainBanner";
import Newsletter from "../../Newsletter/newsletter";
import Faq from "../../Faq/Faq.js";
import YTVideoEmbed from "../../YTVideoEmbed/YTVideoEmbed";

import Quickstory from "../../QuickStory/Quickstory";

const Landing = () => {
  return (
    <div>
      <MainBanner></MainBanner>
      <Quickstory></Quickstory>
      <YTVideoEmbed embedId="9nfO3U-7dQo"></YTVideoEmbed>
      <Newsletter></Newsletter>
      <Faq></Faq>
    </div>
  );
};

export default Landing;
