import { useRef, useState } from "react";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div className="space-y-3 mt-5 overflow-hidden border-b" key={idx} onClick={handleOpenAnswer}>
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        )}
      </h4>
      <div ref={answerElRef} className="duration-300" style={state ? { height: answerH } : { height: "0px" }}>
        <div>
          <p className="text-gray-500">{faqsList.a}</p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const faqsList = [
    {
      q: "¿Dónde se encuentra Camlot?",
      a: "La ubicación exacta es desconocida, algunos viajeros experimentados conocen la ruta al reino. La única información que todos tienen es que Camlot está al Norte del mar azul de Geki. Los marineros cuentan que la isla usualmente está rodeada de una densa bruma.",
    },
    {
      q: "¿Dónde están los habitantes del pueblo?",
      a: "En el evento de los esqueletos se perdió el 90% de la población de Camlot. Se piensa que los habitantes pueden estar pricioneros en alguno de los calabozos o en el laberinto tenebroso. O tal vez los habitantes...",
    },
    {
      q: "¿De dónde proviene el maná que alimenta los poderes mágicos?",
      a: "Nadie lo sabe, es un misterio. Pero el tatarabuelo de Valko cuenta que en una ocasión viajó con un pescador y al tratar de utilizar sus poderes mágicos en el mar tuvo problemas.",
    },
    {
      q: "¿Qué son todas esas estatuas en la isla?",
      a: "Desde generaciones la dinastía de Leónidas se ha dedicado a erigir estatuas en todo el reino. Se piensa que es un tributo a una pasada reina pero la imagen no coincide con ningun registro. Existen otras teórias pero nos meteríamos en problemas al ponerlas aquí. Tendrás que averiguarlo tú mismo.",
    },
    {
      q: "¿Alguna vez alguien ha logrado escapar del laberinto encantado?",
      a: "La leyenda cuenta que cuando los primeros esqueletos aparecieron se inició la construcción de un laberinto para mantenerlos atrapados y que nunca pudieran salir. Aunque algunos marineros creen que fueron los mismos esqueletos los hicieron el laberinto. Tal vez dentro haya alguna pista",
    },
    {
      q: "¿Es cierto que a Leónidas le gusta a pasta?",
      a: "Al igual que su bigote, el gusto por la pasta ha pasado de generación en generación. Aunque en el evento de hace años se perdió la única copia del libro de recetas la receta de la salsa aún vive en la memoria de Leónidas. Según cuentan sus guardas el olor a pasta solía llenar todo el palacio los fines de semana. ",
    },
  ];

  return (
    <section className="leading-relaxed max-w-screen-xl mt-30 mx-auto px-4 lg:px-8">
      <div className="space-y-3 text-center" id="FAQ">
        <h1 className="text-3xl text-gray-800 font-semibold">Preguntas frecuentes</h1>
        <p className="text-gray-600 max-w-lg mx-auto text-lg">
          Si preguntas tienes, las preguntasss frecuentes debes leer
        </p>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;
