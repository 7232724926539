// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqq3xwt0vl0nwBkwpouVHcYdVWmrzcrW0",
  authDomain: "elementdungeonlanding.firebaseapp.com",
  projectId: "elementdungeonlanding",
  storageBucket: "elementdungeonlanding.appspot.com",
  messagingSenderId: "332846400714",
  appId: "1:332846400714:web:1db67de1287bde5386a033",
  measurementId: "G-TGNKWPQ31W",
};

// Initialize Firebase Yeah !
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
