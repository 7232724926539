import steamLogo from "../resources/logoSteam.png";
import steamLogoEarly from "../resources/logoSteamEarlyAccess.png";

const YTVideoEmbed = ({ embedId }) => {
  return (
    <div className="grid mt-20 mb-20 space-y-4 text-center" id="trailerVideo">
      <div className="mb-10">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-14 w-14 mx-auto text-gray-300"> */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="h-14 w-14 mx-auto text-gray-300">
          {/* <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -=-> */}
          <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
        </svg>

        <h1 className="text-3xl text-gray-800 font-semibold">Preview Trailer</h1>
      </div>

      <iframe
        className="place-self-center md:w-2/4 h-full w-full aspect-video"
        // width="853"

        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <div className="grid place-items-center mt-10 ">
        <div className="flex mt-10 ">
          <div className="mr-4 text-l text-gray-800 font-semibold self-end">Próximamente en </div>
          <img src={steamLogo} className="-mb-3"></img>
          <a className="self-end" href="https://store.steampowered.com/genre/Early%20Access/">
            <img src={steamLogoEarly} className=""></img>
          </a>
        </div>
      </div>
    </div>
  );
};
export default YTVideoEmbed;
