import devEduardoPhoto from "../../resources/devEduardoRomaguera.png";
import devTatianaPhoto from "../../resources/devTatianaAraya.png";
import devSaulPhoto from "../../resources/devSaulLopez.png";
import Dev from "./Dev.js";

const Devteam = () => {
  const devEduardo = {
    name: "Eduardo Romaguera",
    position1: "Developer",
    position2: "Product Owner",
    description:
      "Eduardo trabaja actualmente como ingeniero de calidad del software en Growth Acceleration Partners. Es desarrollador y product owner del videojuego Element Dungeon y estudiante de ingeniería en desarrollo de software en Cenfotec. Es una persona de gatos y su juego favorito es Apex Legends. En una vida pasada fue químico y obtuvo un MBA.",
    photo: devEduardoPhoto,
    portafolio: "eromaguera.com",
    linkedin: "www.linkedin.com/in/eromaguera/",
  };
  const devTatiana = {
    name: "Tatiana Araya",
    position1: "Developer",
    position2: "Scrum Master",
    description:
      "Tatiana Araya trabaja como ingeniera de calidad y product owner en Pfizer. Es desarrolladora y scrum master del videojuego Element Dungeon y estudiante de ingeniería en desarrollo de software en Cenfotec. Tiene dos gatitas grises y le encanta Friends. Antes de programar era Ing. Civil.",
    photo: devTatianaPhoto,
    linkedin: "linkedin.com/in/tatiana-araya-mu%C3%B1oz/",
  };
  const devSaul = {
    name: "Saul Lopez",
    position1: "Developer",
    position2: "Art & Graphic Dessign",
    description:
      "Saúl trabaja actualmente como editor de contenido en Publicis Global Delivery. Es desarrollador y encargado de artes y diseño del videojuego Element Dungeon y estudiante de ingeniería en desarrollo de software en Cenfotec. Tiene una gata llamada Lúlu.",
    photo: devSaulPhoto,
    linkedin: "linkedin.com/in/saul-lopez-715536165/",
  };
  return (
    <div>
      <div className="text-center text-3xl text-gray-800 font-semibold my-20"> Equipo de desarrollo</div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 justify-self-center my-20">
        <Dev developer={devEduardo}></Dev>
        <Dev developer={devTatiana}></Dev>
        <Dev developer={devSaul}></Dev>
      </div>
    </div>
  );
};

export default Devteam;
