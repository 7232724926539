import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Footer2 from "./Footer/Footer2";
import MainBanner from "./MainBanner/MainBanner";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import SearchParams from "./SearchParams";
import Newsletter from "./Newsletter/newsletter";
import Faq from "./Faq/Faq.js";
import YTVideoEmbed from "./YTVideoEmbed/YTVideoEmbed";
import Navbar from "./Navbar/Navbar";
import Quickstory from "./QuickStory/Quickstory";
import Landing from "./pages/landing/Landing";
import Devteam from "./pages/devTeam/Devteam";

const App = () => {
  return (
    <div className="bg-[#fffefa] scroll-smooth">
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route exact path="/devTeam" element={<Devteam></Devteam>} />
          <Route exact path="" element={<Landing></Landing>} />
        </Routes>

        <Footer2></Footer2>
      </BrowserRouter>
    </div>
  );
};

ReactDOM.render(React.createElement(App), document.getElementById("root"));
