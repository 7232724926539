import mainBackground from "../resources/mainBackground.jpg";

const MainBanner = () => {
  return (
    <div
      class="sm:pl-80 sm:pr-40 h-[500px] w-full p-10 flex bg-[center_top_-15rem]"
      style={{ backgroundImage: `url(${mainBackground})` }}
    >
      <div className="font-bold text-white w-full">
        <div className="text-6xl drop-shadow-xl sm:text-left">Element Dungeon</div>
        <div className="text-5xl drop-shadow-xl sm:text-left">The Awakening</div>
      </div>
    </div>
  );
};

export default MainBanner;
